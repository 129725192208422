<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Adding new items</h3>

	<p>Because CASE-formatted frameworks follow a hierarchical structure, to create a new item, such as a Standard, you begin by selecting where the item will appear the the framework.</p>

	<p>To add an item to the framework/document:</p>
	<ul>
		<li><span v-html="link('signing_in', 'Sign in')"></span> to <span v-html="app_name"/> an account with sufficient permissions.</li>
		<li><img srcset="/docimages/add_item-1.png 3x" class="k-help-img float-right">Enter “edit mode” by clicking the Edit <nobr>button <v-icon small>fas fa-edit</v-icon></nobr> while a framework's tree display is open. <img srcset="/docimages/edit_document-1.png 3x" class="k-help-img float-right"> The edit button will turn black and have a circle to indicate editing is enabled.</li>
		<li>Select the item that will be the parent or sibling of the item you want to you want to add.</li>
		<li>In the item card that appears, click the Edit <nobr>button <v-icon small>fas fa-edit</v-icon></nobr></li>
		<li>From the menu, select <nobr>“<v-icon small>fas fa-plus</v-icon> Create/Copy/Import”</nobr>.</li>
		<li>From the submenu, select either <nobr>“<v-icon small>fas fa-down-long</v-icon> Create</nobr> a new sibling item” or <nobr>“<v-icon small style="transform:rotate(90deg);">fas fa-turn-up</v-icon> Create</nobr> a new child item”</li>
		<li>Fill in the properties of the new item, as described below.</li>
		<li>Click <nobr><v-icon small>fas fa-save</v-icon> SAVE CHANGES</nobr> to save your edits, or click CANCEL to discard the edits. (Note: If you have not entered any information you will only see a CANCEL button.)</li>
	</ul>

	<p>See <span v-html="link('editing', 'Authoring/editing standards')"></span> for details on other operations available from the editing menu, such as copying and deleting items.</p>

	<p>Item Properties:</p>	
		<img srcset="/docimages/add_item-2.png 3x" class="k-help-img block">
		<ul>
			<li><b>Identifier</b> (required, generated automatically): <span v-html="link('identifiers', 'a globally unique identifier')"></span> meant to serve as an unambiguous reference to the item for any digital system that consumes the item’s CASE JSON</li>
			<li><b>Human-Readable Code</b>: a string of characters, generated according to a framework-specific coding scheme determined by the framework author, allowing humans to easily refer to the item (as opposed to the item’s identifier GUID, which is difficult for humans to remember or refer to).
				<ul><li>Note that it is not required for every item to have a human-readable code, and in fact in many frameworks, organizational branches do not have human-readable codes.</li></ul></li>
			<li><b>Abbreviated Statement</b>: an abbreviated version of the full statement. (This field is not required, and if supplied, it is not defined when consuming systems should use the abbreviated statement as opposed to the full statement.)</li>
			<li><b>Full Statement</b> (required): the text of the item, for example the description of a standard or element (“Ask and answer questions about key details in a text.”), or the name of an organizational “branch” in the framework’s “tree” (“Speaking and Listening”).</li>
			<li><b>Notes</b>: a space for additional information about the item. (Different authors use this space for different purposes.)</li>
			<li><b>Supplemental information</b>: standards issuing agencies often wish to include, for the benefit of educators reviewing standards, additional information along with standards that require additional formatting, links, and/or images. To accommodate this, <span v-html="app_name"/> provides for an “extended CASE field” called <code>supplementalNotes</code> that can include HTML, links, and images. See <span v-html="link('supplemental_info', 'Supplemental item information ')"></span> for details on using this property.</li>
			<li><b>Item Type</b>: a label for the type of competency this item represents.
				<ul><li>While many frameworks use common item types such as “Standard”, “Element”, “Grade Level”, “Course”, etc., the vocabulary of item types used in any framework is up to the author of the framework to determine. Also note that it is not required that an item type be specified for any item.</li></ul></li>
			<li><b>Language</b>: the language of the text used in the item’s full statement and other textual fields</li>
			<li><b>Education Level</b>: the primary instructional level(s) at which the item is intended. This can be a single level (e.g. 05 for grade 5) or a range of levels (e.g. [09,10,11,12] for a standard that may be taught in any US high school grade). After you first select the "Low" end of the range, the "High" value will be set to the same value. You may then adjust the High value to create a range.</li>
			<li><b>Supplemental branch</b>: (advanced setting) See <span v-html="link('supplemental_branches', 'Supplemental Branches')"></span> for details on using this property.</li>
		</ul>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	